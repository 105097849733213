import {
  getDirectMarketingGatewayByCode,
  loadDirectMarketingDisposition,
} from '~/techstyle-shared/react-marketing';
import { getSession, loadSession } from '~/techstyle-shared/redux-core';

async function getDmInfo({ store, newDmgCode }) {
  let dmInfo;
  let sessionDmgCode;
  await store.dispatch(loadSession());
  const session = getSession(store.getState());
  const currentDmgCode = session.dmGatewayCode;
  const dmg =
    newDmgCode && newDmgCode !== 'undefined' ? newDmgCode : currentDmgCode;

  if (dmg) {
    try {
      dmInfo = await store.dispatch(getDirectMarketingGatewayByCode(dmg));
      sessionDmgCode = dmg;
    } catch (e) {
      // this API call can fail given an invalid dmg parameter which could happen through a url
      // parameter or otherwise. We do not want to error out the application or have any negative
      // impact here if this API call fails so we do nothing
    }
  }

  // only set the DM to the session if one is not already set since this is a returning visitor
  const setDmOnSession =
    sessionDmgCode?.length && (newDmgCode || currentDmgCode !== sessionDmgCode);

  if (setDmOnSession) {
    try {
      // load the session with a dmg code and set the disposition for the session
      await Promise.all([
        store.dispatch(loadSession({ dmgCode: sessionDmgCode })),
        store.dispatch(loadDirectMarketingDisposition(sessionDmgCode)),
      ]);
    } catch (e) {
      // simply did not change the disposition, allow it to continue
    }
  }

  return { dmInfo: dmInfo?.payload };
}

export default getDmInfo;
