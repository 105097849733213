import React, { useState } from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { setGenderSessionDetail } from '~/builderQuiz/utils/builderQuiz';
import HeaderLogo from '~/layout/components/HeaderLogo';
import CloseButton from '~/shared/components/CloseButton';
import FableticsModal from '~/shared/components/FableticsModal/FableticsModal';
import featureNames from '~/shared/constants/featureFlags';
import { isYitty } from '~/shared/utils/brandNameHelper';
import relativeUrl from '~/shared/utils/relativeUrl';
import useHasFeature from '~/shared/utils/useHasFeature';
import { AssetContainer } from '~/techstyle-shared/react-assets';
import { useBreakpoint, mobile } from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';
import {
  useDomain,
  useSession,
  useSessionActions,
  useSessionDetail,
} from '~/techstyle-shared/redux-core';

const FTV_VIEWED_SESSION_DETAIL_NAME = 'ftv_modal_clicked';

const modalDialogStyle = css`
  padding: 0;
  ${mobile`
    height: 90vh;
  `}
`;

const ModalCloseButton = styled(CloseButton)`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 18px;
`;

const FtvModalWrapper = styled.div`
  ${mobile`
    width: 90vw;
    height: 100%;
    display: block;
   `}
  ${({ theme }) => theme.colors.ftvModalBackground};
  max-width: 968px;
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: center;
  padding: 48px;
  flex-direction: row;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.header`
  ${mobile`
    font-size: 28px;
 `}
  font-family: ${({ theme }) => theme.fontStacks.baseFamily};
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  line-height: 40px;
`;

const SubHeader = styled.div`
  font-family: ${({ theme }) => theme.fontStacks.baseFamily};
  font-size: 20px;
  margin: auto;
  margin-bottom: 24px;
  text-align: center;
  line-height: 25px;

  ${mobile`
    font-size: 14px;
    width: 75%;
  `}
`;

const ContentArea = styled.div`
  ${mobile`
    flex-direction: column;
  `}
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

const FtvModalButton = styled.a`
  cursor: pointer;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  border-radius: 16px;
  margin: 10px;
  position: relative;
  text-align: center;
`;

const FtvModalButtonImage = styled(AssetContainer)`
  width: 100%;
`;

const FtvModalButtonText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;

const Footer = styled.div`
  padding: 12px;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
`;

const StyledFooterLogo = styled(HeaderLogo)``;

function FtvModal({ showFtvModal, additionalQueryParams, onModalClosed } = {}) {
  const { isLoggedIn } = useSession();
  const sessionActions = useSessionActions();
  const { value: ftvModalClicked } = useSessionDetail(
    FTV_VIEWED_SESSION_DETAIL_NAME
  );

  const initialModalState =
    !isLoggedIn && !ftvModalClicked && !isYitty() && showFtvModal;

  const [isOpen, setIsOpen] = useState(initialModalState);

  const onExit = () => {
    onModalClosed();
    setIsOpen(false);
  };

  const { isMobile } = useBreakpoint();
  const { tld } = useDomain();

  const hasScrubs = useHasFeature(featureNames.SCRUBS);
  const womenAssetContainerName = isMobile
    ? 'Logged_Out_Homepage_Pop_Up__Womens__mobile_'
    : 'Logged_Out_Homepage_Pop_Up__Womens__desktop_';
  const menAssetContainerName = isMobile
    ? 'Logged_Out_Homepage_Pop_Up__Mens__mobile_'
    : 'Logged_Out_Homepage_Pop_Up__Mens__desktop_';
  const scrubsAssetContainerName = isMobile
    ? 'Logged_Out_Homepage_Pop_Up__Scrubs__mobile_'
    : 'Logged_Out_Homepage_Pop_Up__Scrubs__desktop_';
  const dmgCodes = config.get('public.dmgCodes')[tld];
  const womensDefaultDmg = dmgCodes?.womens;
  const mensDefaultDmg = dmgCodes?.mens;
  const scrubsDefaultDmg = dmgCodes?.scrubs;

  const setGender = (gender) => {
    sessionActions.updateSessionDetail({
      name: FTV_VIEWED_SESSION_DETAIL_NAME,
      value: '1',
    });
    if (gender) {
      setGenderSessionDetail(gender, sessionActions.updateSessionDetail);
    }
    setIsOpen(false);
  };

  return (
    <FableticsModal
      title="ftv modal"
      isOpen={isOpen}
      onExit={onExit}
      dialogStyle={modalDialogStyle}
      showCloseButton={false}
      closeButton={<ModalCloseButton />}
    >
      <FtvModalWrapper>
        <HeaderWrapper>
          <Header>
            <FormattedMessage
              id="men_women_modal.ftv_welcome"
              defaultMessage="Welcome to Fabletics!"
            />
          </Header>
          <SubHeader>
            <FormattedMessage
              id="men_women_modal.ftv_started"
              defaultMessage="Select a category to explore."
            />
          </SubHeader>
        </HeaderWrapper>

        <ContentArea>
          <FtvModalButton
            href={relativeUrl('/', {
              gender: 'F',
              dmg: womensDefaultDmg,
              ...additionalQueryParams,
            })}
            data-ga-category="FTV Gender Overlay"
            data-ga-action="Select"
            data-ga-label="FTV-Women"
            onClick={() => setGender('F')}
          >
            <FtvModalButtonImage name={womenAssetContainerName} />
            <FtvModalButtonText>
              <FormattedMessage
                id="men_women_modal.ftv_women"
                defaultMessage="SHOP WOMEN'S"
              />
            </FtvModalButtonText>
          </FtvModalButton>

          <FtvModalButton
            href={relativeUrl('/', {
              gender: 'M',
              dmg: mensDefaultDmg,
              ...additionalQueryParams,
            })}
            data-ga-category="FTV Gender Overlay"
            data-ga-action="Select"
            data-ga-label="FTV-Men"
            onClick={() => setGender('M')}
          >
            <FtvModalButtonImage name={menAssetContainerName} />
            <FtvModalButtonText>
              <FormattedMessage
                id="men_women_modal.ftv_men"
                defaultMessage="SHOP MEN'S"
              />
            </FtvModalButtonText>
          </FtvModalButton>

          {hasScrubs && (
            <FtvModalButton
              href={relativeUrl('/', {
                dmg: scrubsDefaultDmg,
                ...additionalQueryParams,
              })}
              data-ga-category="FTV Gender Overlay"
              data-ga-action="Select"
              data-ga-label="FTV-Scrubs"
              onClick={() => setGender()}
            >
              <FtvModalButtonImage name={scrubsAssetContainerName} />
              <FtvModalButtonText>
                <FormattedMessage
                  id="men_women_modal.ftv_scrubs"
                  defaultMessage="SHOP SCRUBS"
                />
              </FtvModalButtonText>
            </FtvModalButton>
          )}
        </ContentArea>

        <Footer>
          <StyledFooterLogo />
        </Footer>
      </FtvModalWrapper>
    </FableticsModal>
  );
}

FtvModal.propTypes = {
  additionalQueryParams: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  onModalClosed: PropTypes.func,
  showFtvModal: PropTypes.bool,
};

export default FtvModal;
