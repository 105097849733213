export default function getAbsoluteUrl() {
  const url = window.location.origin.toLowerCase();
  const previewMatch = url.match(/preview\d?\.|preview\d?yitty\./);
  if (previewMatch) {
    const previewNumber = previewMatch[0].replace(/preview|yitty|\./g, '');
    return {
      fabletics: 'https://preview' + previewNumber + '.fabletics.com',
      yitty: 'https://preview' + previewNumber + 'yitty.fabletics.com',
    };
  }
  return {
    fabletics: url.includes('yitty.') ? url.replace('yitty.', 'www.') : url,
    yitty: url.includes('yitty.') ? url : url.replace('www.', 'yitty.'),
  };
}
