import config from 'config';

import { redirect } from '~/shared/utils/routing';
import { Gender } from '~/techstyle-shared/react-accounts';
import { updateSessionDetail } from '~/techstyle-shared/redux-core';

export const Platform = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

export const dateFormat = {
  MM_YYYY: 'MM/YYYY',
  MM_DD_YYYY: 'MM/DD/YYYY',
};

export const dateRegex = {
  [dateFormat.MM_YYYY]: /^(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
  [dateFormat.MM_DD_YYYY]:
    /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/(19|20)\d{2}$/,
};

const canadaZipRegex = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/i;
const usZipRegex = /^[0-9]{5}(-[0-9]{4})?$/;
const ausZipRegex = /^[0-9]{4}(-[0-9]{3})?$/;

export const isZipCodeValid = (zipCode, tld, geoCountry = 'US') => {
  if (tld === '.ca') {
    return canadaZipRegex.test(zipCode);
  }
  if (zipCode.length !== 5 && zipCode.length !== 4) {
    return false;
  }
  if (geoCountry === 'AU') {
    return ausZipRegex.test(zipCode);
  }
  return usZipRegex.test(zipCode);
};

/**
 * @function quizStringTemplateReplace
 * Given a string with labeled placeholders, return a string with desired values (if passed).
 * If no value for a placeholder is passed, no value will be shown (and if a trailing slash
 * exists after the placeholder value, the trailing slash is removed)
 * @param {string} template String, with placeholder values wrapped with curly braces or carets
 * @param {object} values Object containing values to replace in template
 * @returns {string} String with desired values (if passed)
 */
export const quizStringTemplateReplace = (template, values) =>
  template?.replace(
    /(?:<|{)(.+?)(?:>|})(\/)?/g,
    (_match, placeholderName, trailingSlash) =>
      values?.[placeholderName]
        ? `${values[placeholderName]}${trailingSlash ? '/' : ''}`
        : ''
  );

export const transformPageName = ({
  pageId,
  gender,
  pageNumber,
  didSkip = false,
  customQuizId,
}) => {
  const optionalValues = {};
  const segmentPageQuizStep = customQuizId
    ? config.get('public.brand.signupQuizSegment.segmentPageQuizStepCustom')
    : config.get('public.brand.signupQuizSegment.segmentPageQuizStep');
  const segmentPageRegistrationStep = customQuizId
    ? config.get(
        'public.brand.signupQuizSegment.segmentPageRegistrationStepCustom'
      )
    : config.get('public.brand.signupQuizSegment.segmentPageRegistrationStep');

  if (config.get('public.brand.isGendered')) {
    optionalValues.gender = gender === Gender.FEMALE ? 'female' : 'male';
  }

  if (
    !pageId ||
    pageId === 'size_questions' ||
    pageId === 'location_questions'
  ) {
    return quizStringTemplateReplace(segmentPageQuizStep, {
      customQuizId,
      pageNumber,
      ...optionalValues,
    });
  } else if (pageId === 'registration_page') {
    return quizStringTemplateReplace(segmentPageRegistrationStep, {
      registrationStep: didSkip ? 'skip-quiz' : 'registration-start',
      customQuizId,
      ...optionalValues,
    });
  }
};

export const getOptOutStatus = (tld, optedIn) => {
  if (tld === '.com' || optedIn) {
    return 'none';
  }

  return 'all';
};

/**
 * @function setGenderSessionDetail
 *
 * Sets "gender" session details - 'gender_select_clicked_womens' and 'gender_select_clicked_mens'
 * @param {string} gender String - 'F' or 'M'
 * @param {function} updateSessionDetail Function -  sessionActions.updateSessionDetail
 */
export const setGenderSessionDetail = (gender, updateSessonDetail) => {
  if (![Gender.MALE, Gender.FEMALE].includes(gender)) {
    return;
  }

  updateSessonDetail({
    name: 'gender_select_clicked_womens',
    value: gender === Gender.FEMALE ? '1' : '0',
  });
  updateSessonDetail({
    name: 'gender_select_clicked_mens',
    value: gender === Gender.MALE ? '1' : '0',
  });
  updateSessonDetail({
    name: 'gender',
    value: gender === Gender.MALE ? 'M' : 'F',
  });
};

export const setGenderSessionDetailSynchronous = async (gender, dispatch) => {
  if (![Gender.MALE, Gender.FEMALE].includes(gender)) {
    return;
  }

  const requests = [];
  requests.push(
    dispatch(
      updateSessionDetail({
        name: 'gender_select_clicked_womens',
        value: gender === Gender.FEMALE ? '1' : '0',
      })
    )
  );
  requests.push(
    dispatch(
      updateSessionDetail({
        name: 'gender_select_clicked_mens',
        value: gender === Gender.MALE ? '1' : '0',
      })
    )
  );
  requests.push(
    dispatch(
      updateSessionDetail({
        name: 'gender',
        value: gender === Gender.MALE ? 'M' : 'F',
      })
    )
  );
  await Promise.all(requests);
};

const addSlashToDateInputByPosition = ({
  prevInput,
  nextInput,
  slashPositions,
}) => {
  for (const slashPosition of slashPositions) {
    // when input chars are increasing
    if (
      nextInput.length === slashPosition &&
      prevInput?.length === slashPosition - 1
    ) {
      return `${nextInput}/`;
    }

    // when input chars are decreasing
    if (
      nextInput.length === slashPosition &&
      prevInput.length === slashPosition + 1
    ) {
      return nextInput.slice(0, slashPosition - 1);
    }
  }

  return nextInput;
};

export const addSlashToDateInput = ({ prevInput, nextInput, format }) => {
  switch (format) {
    case dateFormat.MM_YYYY:
      return addSlashToDateInputByPosition({
        prevInput,
        nextInput,
        slashPositions: [2],
      });
    case dateFormat.MM_DD_YYYY:
      return addSlashToDateInputByPosition({
        prevInput,
        nextInput,
        slashPositions: [2, 5],
      });
    default:
      return nextInput;
  }
};

export const isDateValid = ({ date, format }) => {
  if (!date || !format) {
    return false;
  }
  return dateRegex[format]?.test(date);
};

export const parseDate = ({ date, format }) => {
  if (!isDateValid({ date, format })) {
    return {};
  }

  if (format === dateFormat.MM_YYYY) {
    const [month, year] = date.split('/');
    return { month, year };
  }

  if (format === dateFormat.MM_DD_YYYY) {
    const [month, day, year] = date.split('/');
    return { month, day, year };
  }

  return {};
};

export const getMonthFromDate = ({ date, format }) => {
  const { month } = parseDate({ date, format });
  return month || '';
};

export const getYearFromDate = ({ date, format }) => {
  const { year } = parseDate({ date, format });
  return year || '';
};

export const isBirthdayOver18Years = ({
  date,
  format = dateFormat.MM_YYYY,
}) => {
  const { month: birthdayMonth, year: birthdayYear } = parseDate({
    date,
    format,
  });
  const today = new Date();
  const age = today.getFullYear() - birthdayYear;
  const getMonthOffset = 1;
  const month = today.getMonth() + getMonthOffset - birthdayMonth;

  return month < 0 ? age - 1 >= 18 : age >= 18;
};

/*
  Rules
  1. Always return all isRequired questions(can be more than the max page count)
  2. If we do not have enough pages to reach maxPages, then return all pages
  3. Page order should remain consistent with how it is passed in, just required first, optional second
*/
export const processInitialBuilderPages = (
  pages,
  seed = 2,
  maxQuestionCount = config.get('public.brand.quizMaxQuestionCount')
) => {
  if (!pages) {
    return [];
  } else if (pages.length <= maxQuestionCount) {
    return [...pages];
  }

  // sort the questions into required and optional
  const pageSets = pages.reduce(
    (acc, page) => {
      const required = acc.required;
      const optional = acc.optional;
      if (page?.data?.isRequired) {
        required.push(page);
      } else {
        optional.push(page);
      }
      return { required, optional };
    },
    { required: [], optional: [] }
  );

  // get random indices to work with
  const optionalPageIndicesToShow = [];
  let i = 0;
  while (
    pageSets.required.length + optionalPageIndicesToShow.length <
    maxQuestionCount
  ) {
    const randomValue =
      ((seed % 3671) * (i + 1) + 51 * i) % pageSets.optional.length;
    optionalPageIndicesToShow.push(randomValue);
    i++;
  }

  // process in reverse order, reduces the potential for missing on the end of the optional array
  // and maintain existing order
  const optionalPagesToShow = [];
  // TODO: IF WE DECIDE WE WANT THE OPTIONAL QUESTIONS IN ORDER
  // optionalPageIndicesToShow.sort();
  // optionalPageIndicesToShow.reverse();
  optionalPageIndicesToShow.forEach((optionalPageIndex) => {
    let index = optionalPageIndex;

    // in case we end up with 2 numbers that are the max length, make sure we grab the last one
    if (optionalPageIndex >= pageSets.optional.length) {
      index = pageSets.optional.length - 1;
    }

    // add the item to the pages to show list and remove it from the optional list
    optionalPagesToShow.unshift(pageSets.optional[index]);
    pageSets.optional.splice(index, 1);
  });

  return [...pageSets.required, ...optionalPagesToShow];
};

// logic to allow for redirecting to the builder quiz
// this(and this whole route) can be removed once we fully move to the builder driven quiz
export const handleBuilderQuizRedirect = ({ ctx, quizGender }) => {
  const queryParams = ctx.query
    ? `&${Object.entries(ctx.query)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`
    : '';
  const route = `/quiz?gender=${quizGender}${queryParams}`;
  redirect({ res: ctx.res, url: route, asPush: true });
  return true;
};
