import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { trackHomePageView } from '~/acquisition/actions';
import useCustomerIsGendered from '~/shared/utils/useCustomerIsGendered';
import { useMembership, Gender } from '~/techstyle-shared/react-accounts';
import { useSession } from '~/techstyle-shared/redux-core';

export default function HomepageViewedEvent({ displayPage }) {
  const { isLoggedIn } = useSession();
  const { isLead, isVip } = useMembership();
  const dispatch = useDispatch();
  const registrationComplete = displayPage === 'registration-complete';
  const isPostReg = displayPage === 'postreg';
  const gender = useCustomerIsGendered();

  // tracking page views
  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(
        trackHomePageView({
          pageName: '/homepage/logged-out',
        })
      );
    } else if (isPostReg) {
      dispatch(
        trackHomePageView({
          pageName: '/homepage/post-reg',
        })
      );
    } else if (isVip) {
      dispatch(
        trackHomePageView({
          pageName: '/homepage/vip',
        })
      );
    } else if (registrationComplete) {
      dispatch(
        trackHomePageView({
          pageName:
            gender === Gender.FEMALE
              ? '/style-quiz/registration-complete'
              : '/style-quiz/mens/registration-complete',
        })
      );
    } else if (isLead) {
      dispatch(
        trackHomePageView({
          pageName: '/homepage/lead',
        })
      );
    }
  }, [
    dispatch,
    isLoggedIn,
    isPostReg,
    isVip,
    registrationComplete,
    gender,
    isLead,
  ]);

  return <></>;
}

HomepageViewedEvent.propTypes = {
  displayPage: PropTypes.string,
};
