import React, { useEffect } from 'react';

import config from 'config';
import DOMPurify from 'dompurify';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import getAbsoluteUrl from '~/shared/utils/getAbsoluteUrl';
import { replaceDmgHtml } from '~/shared/utils/helpers';
import usePersistMpid from '~/shared/utils/usePersistMpid';
import { Router } from '~/techstyle-shared/next-routes';
import { Script } from '~/techstyle-shared/react-marketing';
import {
  useHydrationStatus,
  isTruthyQueryParameter,
} from '~/techstyle-shared/redux-core';

import { trackDmgPageView } from '../../actions';

export default function DmsTemplate({ dmgCode, dmInfo, media, name }) {
  const dispatch = useDispatch();
  const hydrated = useHydrationStatus();
  usePersistMpid();
  const { query, pathname } = useRouter();

  useEffect(() => {
    if (
      dmInfo?.dmSiteId &&
      dmInfo?.message !== 'redirect' &&
      pathname !== '/' &&
      !query?.siteId
    ) {
      const queryParams = query
        ? { ...query, siteId: dmInfo.dmSiteId }
        : { siteId: dmInfo.dmSiteId };

      if (isTruthyQueryParameter(query.useDefaultLayout)) {
        queryParams.useDefaultLayout = 'true';
      }
      Router.push({
        pathname: `/dmg/${dmgCode}`,
        query: queryParams,
      });
    }
  }, [dmgCode, dmInfo, pathname, query]);

  if (!dmInfo || !hydrated) {
    return null;
  }

  if (dmInfo?.message === 'redirect') {
    // try to append url params to the url, but if it fails(because of a bad url input), just redirect to the url
    try {
      const queryParams = query
        ? { ...query, code: dmgCode, dmg: dmgCode }
        : { code: dmgCode, dmg: dmgCode };

      let redirectUrl;
      if (dmInfo.redirectUrl.startsWith('http')) {
        redirectUrl = new URL(dmInfo.redirectUrl);
      } else {
        const baseUrls = getAbsoluteUrl();
        if (dmInfo.redirectUrl.includes('yitty.')) {
          redirectUrl = new URL(dmInfo.redirectUrl, baseUrls.yitty);
        } else {
          redirectUrl = new URL(dmInfo.redirectUrl, baseUrls.fabletics);
        }
      }

      const params = new URLSearchParams(redirectUrl.search);
      Object.entries(queryParams).forEach(([key, value]) => {
        params.set(key, value);
      });
      redirectUrl.search = params.toString();
      Router.replace(redirectUrl.toString());
    } catch (e) {
      Router.replace(dmInfo.redirectUrl);
    }
    return null;
  }

  const templateValues = {
    ...config.get('public.dmTemplateVariables'),
  };
  const cdnBaseUrl = config.get('public.cdn');

  const dmsTemplateValues = {
    ...templateValues,
    dmSiteCode: dmInfo.dmSiteId,
  };

  let htmlContent = replaceDmgHtml(dmInfo.htmlCached, dmsTemplateValues);

  // purify the html content we're rendering
  htmlContent = DOMPurify.sanitize(htmlContent);
  htmlContent = DOMPurify.sanitize(htmlContent);

  // This ugly block of code will take a string of HTML meta tags like:
  // '<meta name="title" content="Savage X"> <meta name="description" content="Lingerie">'
  // and turn it into an object with `title` and `description` as properties. This allows us to
  // insert custom meta tags for DMS. This is not possible with `dangerouslySetInnerHTML` or
  // `document.createElement` due to limitations of SSR, and we want to be able to set a
  // `key` to overwrite existing values (i.e. like what's in DefaultHead.js).
  const htmlRegex =
    /(\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/g;
  const metaTags = dmInfo.headTag
    ? dmInfo.headTag
        .split(/[<>]/) // remove HTML open/close tags
        .filter((content) => content.startsWith('meta')) // get only `<meta />` elements
        .reduce((obj, metaTag) => {
          const props = metaTag
            .replace(/"/g, '') // remove double quotes
            .match(htmlRegex) // get HTML props
            .reduce((obj, prop) => {
              // turn all props into { key: val } pairs
              const [key, val] = prop.split('=');
              return { ...obj, [key]: val || true };
            }, {});
          if (!props.name) {
            // if it doesn't have a `name` prop, we don't want it
            return obj;
          }
          return {
            ...obj,
            [props.name]: props,
          };
        }, {})
    : {};

  dispatch(
    trackDmgPageView({
      title: dmInfo.title,
    })
  );

  // `dangerouslySetInnerHTML` is one case where it is safe to
  // tell React to ignore the hydration mismatch warnings
  // TODO: Are these scripts injected properly during client-side navigation?
  return pathname === '/' || query?.siteId ? (
    <>
      <Head>
        {metaTags.description ? (
          <meta {...metaTags.description} key="metadescription" />
        ) : null}
        {dmInfo.title && <title>{dmInfo.title}</title>}
        {dmInfo.cssFile && (
          <link
            rel="stylesheet"
            type="text/css"
            media={media || 'all'}
            href={`${cdnBaseUrl}/css/dm/${dmInfo.cssFile}`}
          />
        )}
      </Head>
      <div
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        suppressHydrationWarning
      />
      {dmInfo.jsFile && (
        <Script
          src={`${cdnBaseUrl}/css/dm/js/${dmInfo.jsFile}`}
          skipServerRender
        />
      )}
    </>
  ) : null;
}

DmsTemplate.propTypes = {
  dmInfo: PropTypes.object,
  dmgCode: PropTypes.string,
  media: PropTypes.string,
  name: PropTypes.string,
};

DmsTemplate.defaultProps = {
  media: '',
};
