const recursiveComponents = ['_Tabs_'];

// make sure dataTag is prefaced with 'data-[your-descriptor]'
const defaultDeprecatedFieldsMap = {
  _Collection_: [
    {
      deprecatedField: 'isCarousel',
      newField: 'isCarouselV2',
    },
  ],
};
export const getDeprecatedFieldClasses = (
  builderJson,
  deprecatedFieldsMap = defaultDeprecatedFieldsMap
) => {
  let deprecatedFields = [];

  // this function is used to parse through the block list to pull
  // all of the components at the top level and under nested objects
  const checkBlockListForDeprecatedFields = (blockList) => {
    let foundList = [];

    blockList.forEach((block) => {
      const { component } = block;
      const { options, name } = component || {};

      // traverse recursive components
      if (recursiveComponents.includes(name)) {
        switch (name) {
          case '_Tabs_': {
            const tabsFoundList = options?.tabs
              ?.map((tab) => [
                ...checkBlockListForDeprecatedFields(tab?.label),
                ...checkBlockListForDeprecatedFields(tab?.content),
              ])
              .reduce(
                (acc, val) =>
                  Array.isArray(val) ? [...acc, ...val] : [...acc, val],
                []
              );
            foundList = [...foundList, ...tabsFoundList];
            break;
          }
        }
      }

      // check for fields here...
      foundList = [
        ...foundList,
        ...getBlockDeprecatedFieldClasses(block, deprecatedFieldsMap),
      ];
    });

    return foundList.filter(
      (value, index, self) => self.indexOf(value) === index
    );
  };

  if (builderJson?.data?.blocks) {
    deprecatedFields = checkBlockListForDeprecatedFields(
      builderJson.data.blocks
    );
  }

  return deprecatedFields;
};

// add deprecated logic here to return the list of classes we want to search for to log
// the deprecated fields
const getBlockDeprecatedFieldClasses = (
  block,
  deprecatedFieldsMap = defaultDeprecatedFieldsMap
) => {
  let deprecatedList = [];
  const { component } = block;
  const { options, name } = component || {};

  const deprecatedFieldsToCheck = deprecatedFieldsMap[name];

  if (options && deprecatedFieldsToCheck) {
    deprecatedFieldsToCheck.forEach((deprecatedField) => {
      // check that the old field is populated and the new field is not populated
      // and apply the data tag if that is the case
      if (
        options[deprecatedField.deprecatedField] != null &&
        options[deprecatedField.newField] == null
      ) {
        deprecatedList = [...deprecatedList, deprecatedField.deprecatedField];
      }
    });
  }
  return deprecatedList;
};
