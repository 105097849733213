import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Logo from '~/shared/components/Logo';

const LogoWrapper = styled.a`
  display: inline-block;
  flex: 0 0 auto;
`;

const HeadNavLogo = styled(Logo)`
  color: ${({ color, theme }) => color || theme.colors.sublabelBlack};
  height: auto;
  ${({ theme }) => theme.headNavLogo};
`;

const getLogoComponent = (ariaLabel, color, width) => {
  return <HeadNavLogo aria-label={ariaLabel} color={color} width={width} />;
};

const HeaderLogo = ({
  'aria-label': ariaLabel,
  color,
  width,
  href,
  ...rest
}) => {
  return (
    <LogoWrapper {...rest} href={href} data-autotag="site-header-logo">
      {getLogoComponent(ariaLabel, color, width)}
    </LogoWrapper>
  );
};

HeaderLogo.propTypes = {
  'aria-label': PropTypes.string,
  color: PropTypes.string,
  href: PropTypes.string,
  width: PropTypes.string,
};

HeaderLogo.defaultProps = {
  'aria-label': 'Logo',
  href: '/',
};

export default HeaderLogo;
