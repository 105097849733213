import { useEffect, useMemo, useState } from 'react';

import { getAllBuilderJson } from './getAllBuilderJson';
import { getBuilderJson } from './getBuilderJson';
import { getBuilderJsonCacheKey } from './getBuilderJsonCacheKey';
import useBuilderTargeting from './useBuilderTargeting';

/*
Spec for API param list:
apiParmList = [{
  modelName,
  urlPath,
  query = {},
  options = {},
  }]
*/
export default function useMultiParallelBuilderJson({
  apiParamList,
  builderQueries = { isPreview: false },
  ssrBuilderJson,
  ssrBuilderJsonKey,
}) {
  const [builderJsonResponses, setBuilderJsonResponses] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  if (
    ssrBuilderJsonKey &&
    ssrBuilderJson &&
    !builderJsonResponses[ssrBuilderJsonKey]
  ) {
    setBuilderJsonResponses({
      ...builderJsonResponses,
      [ssrBuilderJsonKey]: ssrBuilderJson,
    });
  }

  const pageTargetingOptions = useBuilderTargeting({
    builderQueries,
  });

  const cacheKey = useMemo(
    () =>
      getBuilderJsonCacheKey({
        apiParamList,
        pageTargetingOptions,
      }),
    [apiParamList, pageTargetingOptions]
  );

  useEffect(() => {
    const getClientBuilderJson = async () => {
      setIsLoading(true);

      const promises = [];
      for (const apiParams of apiParamList) {
        if (apiParams?.options?.getAll) {
          promises.push(
            getAllBuilderJson({
              modelName: apiParams?.modelName,
              urlPath: apiParams?.urlPath,
              query: apiParams?.query,
              pageTargetingOptions,
            })
          );
        } else {
          promises.push(
            getBuilderJson({
              modelName: apiParams?.modelName,
              urlPath: apiParams?.urlPath,
              query: apiParams?.query,
              pageTargetingOptions,
            })
          );
        }
      }
      const builderJsonResponses = await Promise.all(promises);
      setBuilderJsonResponses((prevBuilderJsonResponses) => ({
        ...prevBuilderJsonResponses,
        [cacheKey]: builderJsonResponses,
      }));
      setIsLoading(false);
    };

    if (!builderJsonResponses[cacheKey]) {
      getClientBuilderJson();
    }
    // the cacheKey has all the dependencies needed for this
    // if you add dependencies that are also in the cache key,
    // this will go into an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheKey]);

  return { builderJsonResponses: builderJsonResponses[cacheKey], isLoading };
}
