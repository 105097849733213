import React, { useMemo, useState } from 'react';

import { BuilderComponent } from '@builder.io/react';
import config from 'config';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import DmsTemplate from '~/acquisition/components/DmsTemplate';
import FtvModal from '~/acquisition/components/FtvModal';
import HomepageViewedEvent from '~/acquisition/components/HomepageViewedEvent';
import { PromoPickerModal } from '~/acquisition/components/PromoPicker';
import { getExperienceId } from '~/acquisition/utils/customizedExperience';
import { getHomePageContent } from '~/acquisition/utils/getHomePageContent';
import { useHomePageContent } from '~/acquisition/utils/useHomePageContent';
import usePromoPicker, {
  PROMO_PICKER_MODAL_PARAMETER,
} from '~/acquisition/utils/usePromoPicker';
import PromoTrackingWrapper from '~/builder/components/PromoTrackingWrapper/PromoTrackingWrapper';
import { trackBuilderTest } from '~/builder/utils/builderTesting';
import { getDeprecatedFieldClasses } from '~/builder/utils/getDeprecatedFieldClasses';
import {
  registerBuilderComponents,
  registerBuilderMenu,
  allComponents,
} from '~/builder/utils/registerBuilderComponents';
import { useBuilderContent } from '~/builder/utils/useBuilderContent';
import DefaultLayout from '~/layout/components/DefaultLayout';
import featureNames from '~/shared/constants/featureFlags';
import { isYitty } from '~/shared/utils/brandNameHelper';
import { loadMembership } from '~/techstyle-shared/react-accounts';
import { defineMessages } from '~/techstyle-shared/react-intl';
import { getDirectMarketingDisposition } from '~/techstyle-shared/react-marketing';
import { getSession, loadSession } from '~/techstyle-shared/redux-core';

import createPage from '../registry';

const dynamicDefineMessages = defineMessages;
const seo = dynamicDefineMessages({
  title: {
    id: 'site_seo_title.homepage_title',
    defaultMessage: config.get('public.brand.label'),
  },
  description: {
    id: 'site_seo_description.homepage_description',
    defaultMessage: `Welcome to ${config.get('public.brand.label')}!`,
  },
});

function HomePage({
  dmg,
  dmInfo,
  displayPage,
  builderJson,
  builderStateData = {},
}) {
  const isPostReg = displayPage === 'postreg';
  const dispatch = useDispatch();

  // adjust getHomePageContent content to be in a hook
  const {
    builderJson: clientBuilderJson,
    defaultBuilderJson,
    isLoading,
  } = useHomePageContent({ dmg, dmInfo });

  const {
    isEnabled: isPromoPickerModalEnabled,
    isFirstPageView,
    showModal: showPromoPickerModal,
    wasClosed: wasPromoPickerClosed,
  } = usePromoPicker();

  /**
   * @var queryParams
   * @description apply `PROMO_PICKER_MODAL_PARAMETER` to the URL if the user clicks on
   * an FTV modal option and it is the users first page view.
   */
  const queryParams =
    isPromoPickerModalEnabled && isFirstPageView
      ? {
          [PROMO_PICKER_MODAL_PARAMETER]: 1,
        }
      : null;

  const [ftvModalOutsideClicked, setFtvModalOutsideClicked] = useState(false);
  const onFtvModalClosed = () => {
    setFtvModalOutsideClicked(true);
  };

  // determine which builder json to use
  const builderJsonToShow = useMemo(() => {
    if (clientBuilderJson) {
      return clientBuilderJson;
    } else if (defaultBuilderJson) {
      return defaultBuilderJson;
    } else {
      return builderJson;
    }
  }, [builderJson, clientBuilderJson, defaultBuilderJson]);

  const { content, locale } = useBuilderContent(builderJsonToShow);
  const showBuilder = !!content && !isLoading;
  const showDm = !showBuilder && displayPage === 'dm' && !isLoading;

  const deprecatedBuilderFields = useMemo(() => {
    return getDeprecatedFieldClasses(builderJsonToShow);
  }, [builderJsonToShow]);

  let noHeader = false;
  let noFooter = false;
  let showFtvModal = false;

  // builder rendering logic
  if (showBuilder) {
    const {
      title = config.get('public.brand.label'),
      description = title,
      showFtvModal: inputShowFtvModal,
      hideHeader,
      hideFooter,
    } = content?.data;
    showFtvModal = inputShowFtvModal;
    seo.title = title;
    seo.description = description;
    noHeader = hideHeader;
    noFooter = hideFooter;
  }

  /**
   * @var showPPP
   * @description renders PPP if the following business logic conditions are satisfied:
   * - The first condition takes business logic as defined in `usePromoPicker`.
   * - The second condition supports if a user clicks outside of the FTV modal which does not
   * apply a URL parameter for the display of PPP but business requirements call for the display
   * of PPP regardless.
   * - The third condition supports FTV pages that do not include an FTV modal to interract
   * with beforehand.
   * - The fourth condition supports a user on the Yitty domain. Yitty does not have an FTV modal
   * but Builder users can still turn on the FTV modal at the Yitty FTV page level hence the need
   * to include this condition. Once this content adjustment is made, this condition can be
   * removed in favor of relying on the third condition for Yitty.
   */
  const showPPP =
    showPromoPickerModal ||
    (isPromoPickerModalEnabled && ftvModalOutsideClicked) ||
    (isPromoPickerModalEnabled && !wasPromoPickerClosed && !showFtvModal) ||
    (isPromoPickerModalEnabled && !wasPromoPickerClosed && isYitty());

  // right now, defaultDMG should be the only thing for the homepage
  return (
    <DefaultLayout
      title={seo.title}
      description={seo.description}
      showPostRegTimer={!isPostReg}
      showMobilePostRegTimer={false}
      data-builder-deprecated-field={deprecatedBuilderFields.join()}
      noHeader={noHeader}
      noFooter={noFooter}
    >
      <HomepageViewedEvent displayPage={displayPage} />
      <PromoTrackingWrapper>
        {showBuilder && (
          <BuilderComponent
            model="page"
            options={{
              enrich: true,
            }}
            content={content}
            locale={locale}
            data={builderStateData}
            contentLoaded={(data, content) => {
              trackBuilderTest(dispatch, content);
            }}
          />
        )}
        {showDm && <DmsTemplate dmgCode={dmg} dmInfo={dmInfo} />}
      </PromoTrackingWrapper>
      {showPPP ? (
        <PromoPickerModal />
      ) : (
        <FtvModal
          onModalClosed={onFtvModalClosed}
          showFtvModal={showFtvModal}
          additionalQueryParams={queryParams}
        />
      )}
    </DefaultLayout>
  );
}

HomePage.propTypes = {
  builderJson: PropTypes.object,
  builderStateData: PropTypes.object,
  displayPage: PropTypes.string,
  dmInfo: PropTypes.object,
  dmg: PropTypes.string,
};

HomePage.getInitialProps = async (ctx) => {
  const { query } = ctx;
  await ctx.store.dispatch(loadSession());
  await ctx.store.dispatch(loadMembership());
  const session = getSession(ctx.store.getState());

  const { isLoggedIn } = session;

  try {
    const disposition = getDirectMarketingDisposition(ctx.store.getState());
    await getExperienceId({
      isLoggedIn,
      disposition,
      dmg: query?.dmg,
      gender: query?.gender,
      dispatch: ctx.store.dispatch,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('FAILED TO RETRIEVE DISPOSITION!!!!');
  }

  const homepageContent = await getHomePageContent(ctx, isLoggedIn);

  const { builderJson, dmg, dmInfo, displayPage, builderStateData } =
    homepageContent;

  return {
    dmg,
    dmInfo,
    displayPage,
    isLoggedIn,
    builderJson,
    builderStateData,
  };
};

registerBuilderComponents(allComponents);
registerBuilderMenu();

export default createPage(HomePage, {
  prefetchAssets: [
    'Logged_Out_Homepage_Pop_Up__Womens__mobile_',
    'Logged_Out_Homepage_Pop_Up__Womens__desktop_',
    'Logged_Out_Homepage_Pop_Up__Mens__mobile_',
    'Logged_Out_Homepage_Pop_Up__Mens__desktop_',
    'Logged_Out_Homepage_Pop_Up__Scrubs__mobile_',
    'Logged_Out_Homepage_Pop_Up__Scrubs__desktop_',
  ],
  prefetchFeatures: [
    featureNames.ENABLE_MOBILE_POSTREG_REDESIGN,
    featureNames.DENIM_INTEREST,
  ],
  resourceBundles: [
    'grid',
    'member_box',
    'site_product_filter',
    'site_pdp_callout',
    'collection_page',
    'BMIG_Vip_Homepage_Womens',
    'BMIG_Vip_Homepage_Mens',
    'Marketing_card',
    'bill_me_now',
    'men_women_modal',
    'post_reg',
    'size_guides',
    'ppp',
    'signup',
  ],
});
