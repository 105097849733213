import config from 'config';

import { Gender, useCustomerDetails } from '~/techstyle-shared/react-accounts';

const isGendered = config.get('public.brand.isGendered');

/**
 * Some brands (Yitty) do not have gendered users, and will
 * default to Female gender for all users.
 */
export default function useCustomerIsGendered() {
  const [gender] = useCustomerDetails(['gender']);

  if (isGendered && gender) {
    return gender;
  }

  return Gender.FEMALE;
}
