import useMultiParallelBuilderJson from '~/builder/utils/useMultiParallelBuilderJson';
import { useSession } from '~/techstyle-shared/redux-core';

export function useHomePageContent({ dmInfo, dmg }) {
  let modelName = 'page';
  let urlPath = '/';

  const session = useSession();

  if (!session?.isLoggedIn) {
    // if we're going to try to use builder for this landing page
    if (dmInfo?.message === 'redirect' && dmInfo?.redirectUrl) {
      // we don't need the domain so this should work fine for parsing url pathname
      const placeHolderBaseUrl = 'https://www.test.iox';
      const url = new URL(dmInfo.redirectUrl, placeHolderBaseUrl);
      const builderUrl = url.pathname;

      if (builderUrl?.length) {
        modelName = 'landing-page';
        urlPath = builderUrl;
      }
    }
  }

  // optimize to load these in parallel
  const { builderJsonResponses, isLoading } = useMultiParallelBuilderJson({
    apiParamList: [
      {
        modelName: 'page',
        urlPath: '/',
      },
      {
        modelName,
        urlPath,
      },
    ],
  });
  const defaultBuilderJsonResponse = builderJsonResponses?.length
    ? builderJsonResponses[0]
    : null;
  const defaultBuilderJson = defaultBuilderJsonResponse?.builderJson;

  const builderJsonResponse =
    builderJsonResponses?.length > 1 ? builderJsonResponses[1] : null;
  const builderJson = builderJsonResponse?.builderJson;

  return {
    defaultBuilderJson,
    builderJson,
    dmInfo,
    dmg,
    isLoading,
  };
}
