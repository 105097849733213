import { getBuilderJsonSSR } from '~/builder/utils/getBuilderJsonSSR';
import { loadMembership } from '~/techstyle-shared/react-accounts';
import { getDirectMarketingSiteById } from '~/techstyle-shared/react-marketing';

import getDmInfo from './getDmInfo';

export async function getHomePageContent(ctx, isLoggedIn) {
  let dmg, dmInfo, displayPage, routeConfig, builderJson, builderStateData;

  if (isLoggedIn) {
    await ctx.store.dispatch(loadMembership());
    const builderJsonResponse = await getBuilderJsonSSR({
      modelName: 'page',
      urlPath: '/',
      ctx,
    });
    builderJson = builderJsonResponse?.builderJson;
    builderStateData = builderJsonResponse?.builderStateData;
  } else {
    const dmInfoReturn = await getDmInfo({
      store: ctx?.store,
      newDmgCode: ctx?.query?.dmg,
    });
    dmInfo = dmInfoReturn?.dmInfo;
    dmg = dmInfoReturn?.dmg;

    displayPage = 'dm';
    const dmsQuery = parseInt(ctx.query.dm_site, 10);

    if (dmsQuery) {
      // Manually view a dms site with the `?dm_site=` query override
      const dmsReturn = await ctx.store.dispatch(
        getDirectMarketingSiteById(dmsQuery)
      );
      dmInfo = dmsReturn?.payload;
    }

    // if we're going to try to use builder for this landing page
    if (dmInfo?.message === 'redirect' && dmInfo?.redirectUrl) {
      // we don't need the domain so this should work fine for parsing url pathname
      const placeHolderBaseUrl = 'https://www.test.iox';
      const url = new URL(dmInfo.redirectUrl, placeHolderBaseUrl);
      const builderUrl = url.pathname;

      if (builderUrl?.length) {
        const builderJsonResponse = await getBuilderJsonSSR({
          modelName: 'landing-page',
          urlPath: builderUrl,
          ctx,
        });
        builderJson = builderJsonResponse?.builderJson;
      }
    }

    // if no builderJson is found, fallback to the logged out targeted '/' builder page
    if (!builderJson) {
      const builderJsonResponse = await getBuilderJsonSSR({
        modelName: 'page',
        urlPath: '/',
        ctx,
      });
      builderJson = builderJsonResponse?.builderJson;
    }
  }

  return {
    dmInfo,
    dmg,
    displayPage,
    routeConfig,
    builderJson,
    builderStateData,
  };
}
