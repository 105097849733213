import React from 'react';

import PropTypes from 'prop-types';

import usePromoTracking from '~/shared/utils/usePromoTracking';
import { ListViewedProvider as PromoListViewedProvider } from '~/techstyle-shared/react-components';

const PromoTrackingWrapper = ({ children }) => {
  const { onPromoViewed } = usePromoTracking();
  return (
    <PromoListViewedProvider onItemViewed={onPromoViewed}>
      {children}
    </PromoListViewedProvider>
  );
};

PromoTrackingWrapper.propTypes = {
  children: PropTypes.node,
};

export default PromoTrackingWrapper;
