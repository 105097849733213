import { useDispatch } from 'react-redux';

import { trackPromoClicked, trackPromoViewed } from '../actions';

export default function usePromoTracking() {
  const dispatch = useDispatch();
  const triggerPromoTracking = (asset) => {
    if (asset?.options?.targetUrl || asset?.options?.customVars?.link) {
      dispatch(trackPromoClicked(asset));
    }
  };

  const onPromoViewed = ({ itemInfo, id }) => {
    dispatch(trackPromoViewed({ itemInfo, id }));
  };

  const onPromoClicked = (asset) => {
    dispatch(trackPromoClicked(asset));
  };

  return {
    triggerPromoTracking,
    onPromoViewed,
    onPromoClicked,
  };
}
